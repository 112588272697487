import { Descriptions, Spin, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { createContext, useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import BackTitle from "../common/BackTitle";
import { emptyOrPlaceholder } from "../util";
import OrderAccountingSectionItems from "./OrderAccountingSectionItems";
import OrderAccountingItems from "./OrderAccountingItems";

export const AccountingContext = createContext();

export default function AccountingDetails() {
    const { orderId, accountingId } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        return axiosInstance
            .get(`/accounting/${accountingId}`)
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <Spin spinning={loading}>
                <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                    <BackTitle
                        padding={0}
                        title={`Foglio di contabilità: ${emptyOrPlaceholder(data.name)}`}
                    />
                    <Descriptions layout="vertical" size="small">
                        <Descriptions.Item label="Commessa">{data.order?.title}</Descriptions.Item>
                        <Descriptions.Item label="Cliente">
                            {data.order?.contract.customer}
                        </Descriptions.Item>
                    </Descriptions>

                    <AccountingContext.Provider value={data}>
                        <Tabs
                            destroyInactiveTabPane
                            items={[
                                {
                                    key: 1,
                                    label: "Visualizzazione classica",
                                    children: (
                                        <OrderAccountingItems
                                            refresh={() => fetchData()}
                                            data={data.items ?? []}
                                        />
                                    ),
                                },
                                {
                                    key: 2,
                                    label: "Visualizzazione per tratta",
                                    children: (
                                        <OrderAccountingSectionItems
                                            refresh={() => fetchData()}
                                            data={data.items ?? []}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </AccountingContext.Provider>
                </div>
            </Spin>
        </div>
    );
}
