import { DatePicker, Form, Input, InputNumber, message, Modal, Select } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";
import { useForm } from "antd/lib/form/Form";
import { OrderContext } from "../order/OrderDetails";

export default function NewInvoicedAccountingItem({ onOk, onCancel, open }) {
    const [contractItems, setContractItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const ref = useRef(null);
    const [month, setMonth] = useState();

    const order = useContext(OrderContext);

    useEffect(() => {
        if (order.id) {
            axiosInstance
                .get(`contractItem/compact`, { params: { contract: order.contract.id } })
                .then(({ data }) => setContractItems(data));
        }
    }, [order]);

    useEffect(() => {
        if (open) 
            form.setFieldValue("month", month);
    }, [open]);

    const onFinish = (formData) => {
        setLoading(true);
        setMonth(formData.month);
        formData.month = formData.month.format("YYYY-MM-DD");
        axiosInstance
            .post(`invoicedAccountingItem`, { ...formData, order: order.id })
            .then(({ data }) => {
                onOk?.(data);
                form.resetFields();
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            afterOpenChange={op => op && ref.current.focus()}
            okButtonProps={{ htmlType: "submit", form: "newInvoicedAccountingItem", loading: loading }}
            title="Nuovo elemento fatturato"
            onCancel={() => {
                onCancel?.();
                form.resetFields();
            }}
        >
            <Form
                onFinish={onFinish}
                id="newInvoicedAccountingItem"
                form={form}
                layout={"vertical"}
            >
                <Form.Item label={"Competenza"} name={"month"} rules={[requiredField]}>
                    <DatePicker.MonthPicker />
                </Form.Item>
                <Form.Item label={"Voce"} name={"contractItem"} rules={[requiredField]}>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        ref={ref}
                        options={contractItems.map((x) => ({
                            label: x.refId + " - " + x.description,
                            value: x.id,
                        }))}
                    />
                </Form.Item>
                <Form.Item label={"Quantità"} name={"quantity"} rules={[requiredField]}>
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item label={"Importo"} name={"totalPrice"} rules={[requiredField]}>
                    <InputNumber style={{ width: "100%" }} precision={2} decimalSeparator={","} />
                </Form.Item>
                <Form.Item label={"Note"} name={"notes"}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}
