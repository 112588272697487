import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Avatar, Button, message, Popover, Table, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import {
    CheckCircleTwoTone,
    CheckOutlined,
    CloseCircleTwoTone,
    FileExcelOutlined,
    PlusOutlined,
    SendOutlined,
} from "@ant-design/icons";
import Toolbar from "../common/Toolbar";
import { downloadFile, emptyOrPlaceholder, formatDateTime } from "../util";
import SelectEmailRecipients from "./SelectEmailRecipients";
import dayjs from "dayjs";
import NewOrderAccounting from "./NewOrderAccounting";
import { UserContext } from "../App";
import PermissionRequired from "../common/PermissionRequired";

const columns = [
    {
        key: "id",
        dataIndex: "id",
        width: 80,
        ellipsis:true,
        title: "Foglio n.",
    },
    {
        key: "name",
        dataIndex: "name",
        ellipsis:true,
        width: 150,
        title: "Nome",
        render: (x, record) => <Link to={`accountings/${record.id}`}>{emptyOrPlaceholder(x)}</Link>,
    },
    {
        key: "month",
        dataIndex: "month",
        width: 100,
        ellipsis:true,
        title: "Competenza",
        render: (x) => dayjs(x).format("MMMM YYYY"),
    },
    {
        key: "assignedTo",
        ellipsis: true,
        dataIndex: "assignedTo",
        title: "Assegnatario",
        width: 70,
        render: (x) =>
            x ? (
                <Tooltip title={x.fullName} placement="top">
                    <Avatar
                        size={"small"}
                        style={{
                            backgroundColor: "orange",
                        }}
                    >
                        {x.fullName
                            .split(" ")
                            .map((z) => z[0].toUpperCase())
                            .join("")}
                    </Avatar>
                </Tooltip>
            ) : (
                ""
            ),
    },
    {
        key: "emailSent",
        dataIndex: "emailSent",
        ellipsis:true,
        title: "Inviato",
        width: 50,
        render: (x) =>
            x ? (
                <CheckCircleTwoTone twoToneColor="green" />
            ) : (
                <CloseCircleTwoTone twoToneColor="red" />
            ),
    },
    {
        key: "createdBy",
        dataIndex: "createdBy",
        width: 100,
        ellipsis:true,
        title: "Creato da",
        render: (x) => x.firstName + " " + x.lastName,
    },

    {
        key: "creationDate",
        dataIndex: "creationTs",
        title: "Data creazione",
        ellipsis:true,
        width: 100,
        render: formatDateTime,
    },
    {
        key: "updatedBy",
        dataIndex: "updatedBy",
        title: "Modificato da",
        width: 100,
        ellipsis:true,
        render: (x) => x.firstName + " " + x.lastName,
    },

    {
        key: "updateDate",
        dataIndex: "updateTs",
        width: 100,
        title: "Data modifica",
        ellipsis:true,
        render: formatDateTime,
    },
];

export default function OrderAccountings({ customerId }) {
    const { orderId } = useParams();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [sendMailLoading, setSendMailLoading] = useState(false);
    const navigate = useNavigate();
    const [newAccountingOpen, setNewAccountingOpen] = useState(false);
    const [selectEmailRecipientsOpen, setSelectEmailRecipientsOpen] = useState(false);
    const user = useContext(UserContext);

    const fetchData = () => {
        setSelectedRowKeys([]);
        setLoading(true);
        const params = {
            ...[...searchParams.entries()].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}),
            orderId: orderId,
        };
        axiosInstance
            .get(`accounting`, { params })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        fetchData();
    }, [searchParams]);

    const deleteAccountings = (keys) => {
        setLoading(true);
        axiosInstance
            .delete(`/accounting?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(() => fetchData())
            .finally(() => setLoading(false));
    };

    const downloadExcelReport = (keys) =>
        downloadFile(
            `/order/${orderId}/accountingReport/export?${keys.map((x) => `ids=${x}`).join("&")}`,
        );

    const flagAsSent = (keys) => {
        setLoading(true);
        axiosInstance
            .put(`/accounting/flagAsSent?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(() => {
                message.success("Elementi aggiornati");
                fetchData();
            })
            .finally(() => setLoading(false));
    };

    const page = searchParams.get("page") ?? "0";
    const pageSize = searchParams.get("pageSize") ?? "10";

    return (
        <div>
            <SelectEmailRecipients
                customerId={customerId}
                open={selectEmailRecipientsOpen}
                onCancel={() => setSelectEmailRecipientsOpen(false)}
                onFinish={(data) => {
                    setSelectEmailRecipientsOpen(false);
                    setSendMailLoading(true);

                    const formData = new FormData();
                    formData.append("to", data.to);
                    (data.attachments?.fileList ?? []).forEach((x) =>
                        formData.append("attachments[]", x.originFileObj),
                    );
                    formData.append("to", data.to);
                    data.notes && formData.append("notes", data.notes);
                    (data.ccs ?? []).forEach((x) => formData.append("ccs[]", x));
                    selectedRowKeys.forEach((x) => formData.append("accountingIds[]", x));

                    axiosInstance
                        .post(`order/${orderId}/accountingReport/mail`, formData, {
                            headers: { "Content-Type": "multipart/form-data" },
                        })
                        .then(() => {
                            message.success("Mail inviata");
                            fetchData();
                        })
                        .then(() => setSelectedRowKeys([]))
                        .finally(() => {
                            setSendMailLoading(false);
                        });
                }}
            />
            <Toolbar
                selectedKeys={selectedRowKeys}
                addButton={
                    <PermissionRequired name={"accounting.create"}>
                        <Popover
                            trigger={"click"}
                            open={newAccountingOpen}
                            onOpenChange={setNewAccountingOpen}
                            destroyTooltipOnHide
                            placement={"right"}
                            content={
                                <NewOrderAccounting
                                    onOk={() => {
                                        fetchData();
                                        setNewAccountingOpen(false);
                                    }}
                                />
                            }
                        >
                            <Button type={"primary"} icon={<PlusOutlined />}>
                                Nuovo foglio
                            </Button>
                        </Popover>
                    </PermissionRequired>
                }
                addAction={
                    user.permissions?.includes("accounting.create")
                        ? () => navigate("accountings/new")
                        : null
                }
                editAction={
                    user.permissions?.includes("accounting.update")
                        ? (key) => navigate(`accountings/${key}/edit`)
                        : null
                }
                deleteAction={
                    user.permissions?.includes("accounting.delete") ? deleteAccountings : null
                }
                extra={(keys) => (
                    <>
                        <PermissionRequired name={"accounting.read"}>
                            <Button
                                disabled={keys.length === 0}
                                type={"text"}
                                icon={<FileExcelOutlined />}
                                onClick={() => downloadExcelReport(keys)}
                            >
                                Esporta
                            </Button>
                        </PermissionRequired>
                        <PermissionRequired name={"accounting.sendEmail"}>
                            <Button
                                loading={sendMailLoading}
                                onClick={() => setSelectEmailRecipientsOpen(true)}
                                disabled={keys.length === 0}
                                type={"text"}
                                icon={<SendOutlined />}
                            >
                                Invia email
                            </Button>
                        </PermissionRequired>
                        <PermissionRequired name={"accounting.markAsSent"}>
                            <Button
                                disabled={keys.length === 0}
                                type={"text"}
                                icon={<CheckOutlined />}
                                onClick={() => flagAsSent(keys)}
                            >
                                Segna come inviato
                            </Button>
                        </PermissionRequired>
                    </>
                )}
            />
            <Table
                size={"small"}
                loading={loading}
                columns={columns}
                tableLayout="fixed"
                scroll={{ x: columns.reduce((prev, curr) => prev + curr.width, 0) }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            setSelectedRowKeys([record.id]);
                        }, // click row
                    };
                }}
                rowSelection={{ selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys }}
                onChange={(pagination) => {
                    searchParams.set("page", "" + (pagination.current - 1));
                    searchParams.set("pageSize", "" + pagination.pageSize);
                    setSearchParams(searchParams, { replace: true });
                }}
                pagination={{
                    current: Number(page) + 1,
                    showTotal: (total) => "Totale: " + total,
                    pageSize: Number(pageSize),
                    total: data.count ?? 0,
                }}
                rowKey="id"
                dataSource={data.data}
            />
        </div>
    );
}
