import { DatePicker, Form, Input, InputNumber, message, Modal, Select } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";
import { useForm } from "antd/lib/form/Form";
import dayjs from 'dayjs'
import { OrderContext } from "../order/OrderDetails";

export default function ({ onOk, onCancel, open, item }) {
    const [contractItems,setContractItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const ref = useRef(null);

    const order = useContext(OrderContext);

    useEffect(() => {
        if (order.id) {
            axiosInstance
                .get(`contractItem/compact`, { params: { contract: order.contract.id } })
                .then(({ data }) => setContractItems(data));
        }
    }, [order]);

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                ...item,
                month: dayjs(item.month),
                contractItem: item.contractItem.id
            });
        }
    }, [item]);

    const onFinish = (formData) => {
        setLoading(true);
        formData.month = formData.month.format("YYYY-MM-DD");
        axiosInstance
            .put(`invoicedAccountingItem/${item.id}`, formData)
            .then(({ data }) => {
                onOk?.(data);
                form.resetFields();
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={!!item}
            afterOpenChange={(op) => op && ref.current.focus()}
            okButtonProps={{
                htmlType: "submit",
                form: "updateInvoicedAccountingItem",
                loading: loading,
            }}
            title="Modifica elemento fatturato"
            onCancel={() => {
                onCancel?.();
                form.resetFields();
            }}
        >
            <Form
                onFinish={onFinish}
                id="updateInvoicedAccountingItem"
                form={form}
                layout={"vertical"}
            >
                <Form.Item label={"Competenza"} name={"month"} rules={[requiredField]}>
                    <DatePicker.MonthPicker />
                </Form.Item>
                <Form.Item label={"Voce"} name={"contractItem"} rules={[requiredField]}>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        ref={ref}
                        options={contractItems.map((x) => ({
                            label: x.refId + " - " + x.description,
                            value: x.id,
                        }))}
                    />
                </Form.Item>
                <Form.Item label={"Quantità"} name={"quantity"} rules={[requiredField]}>
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item label={"Importo"} name={"totalPrice"} rules={[requiredField]}>
                    <InputNumber style={{ width: "100%" }} precision={2} decimalSeparator={","} />
                </Form.Item>
                <Form.Item label={"Note"} name={"notes"}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}
