import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table,
} from "antd";
import { useSearchParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { filterOption, requiredField, withTooltip } from "../util";
import Toolbar from "../common/Toolbar";
import { UserContext } from "../App";

const NewItemModal = ({ onOk, onCancel, visible }) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axiosInstance.get("itemCategory").then(({ data }) => setCategories(data));
    }, []);

    useEffect(() => {
        document.body.style.overflowY = "scroll";
    }, []);

    return (
        <Modal
            title="Nuovo articolo"
            open={visible}
            okButtonProps={{ htmlType: "submit", form: "newItemForm" }}
            onCancel={() => {
                form.resetFields();
                onCancel?.();
            }}
        >
            <Form
                form={form}
                id="newItemForm"
                layout="vertical"
                onFinish={(data) => {
                    setLoading(true);
                    axiosInstance
                        .post("item", [data])
                        .then(() => {
                            onOk?.();
                        })
                        .then(() => form.resetFields());
                }}
            >
                <Form.Item label={"Categoria"} name={"category"} rules={[requiredField]}>
                    <Select
                        allowClear
                        filterOption={filterOption}
                        showSearch
                        options={categories.map((x) => ({ label: x.name, value: x.id }))}
                    />
                </Form.Item>
                <Form.Item label={"Etichetta"} name={"label"}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Voce"} name={"refId"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Unità di misura"} name={"measureUnit"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Prezzo base/Punti"} name={"basePrice"} rules={[requiredField]}>
                    <InputNumber min={0}  precision={2} decimalSeparator={","}/>
                </Form.Item>
                <Form.Item label={"Coefficiente"} name={"applyMultiplier"} rules={[requiredField]}>
                    <Radio.Group>
                        <Radio value={true}>Applica</Radio>
                        <Radio value={false}>Non applicare</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const EditItemModal = ({ id, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axiosInstance.get("itemCategory").then(({ data }) => setCategories(data));
    }, []);

    useEffect(() => {
        if (id) {
            document.body.style.overflowY = "scroll";
            setDataLoading(true);
            axiosInstance
                .get(`item/${id}`)
                .then(({ data }) => form.setFieldsValue({ ...data, category: data.category.id }))
                .finally(() => setDataLoading(false));
        } else form.resetFields();
    }, [id]);

    return (
        <Modal
            title="Modifica articolo"
            open={!!id}
            okButtonProps={{ htmlType: "submit", form: "editItemForm" }}
            onCancel={() => {
                form.resetFields();
                onCancel?.();
            }}
        >
            <Form
                form={form}
                id="editItemForm"
                layout="vertical"
                onFinish={(data) => {
                    axiosInstance
                        .put(`item/${id}`, data)
                        .then(() => {
                            onOk?.();
                        })
                        .then(() => form.resetFields());
                }}
            >
                <Form.Item label={"Categoria"} name={"category"} rules={[requiredField]}>
                    <Select
                        filterOption={filterOption}
                        showSearch
                        allowClear
                        options={categories.map((x) => ({ label: x.name, value: x.id }))}
                    />
                </Form.Item>
                <Form.Item label={"Etichetta"} name={"label"}>
                    <Input />
                </Form.Item>
                <Form.Item  label={"Codice articolo"} name={"refId"} rules={[requiredField]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Unità di misura"} name={"measureUnit"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Prezzo base/Punti"} name={"basePrice"} rules={[requiredField]}>
                    <InputNumber min={0}  precision={2} decimalSeparator={","}/>
                </Form.Item>
                <Form.Item label={"Coefficiente"} name={"applyMultiplier"} rules={[requiredField]}>
                    <Radio.Group>
                        <Radio value={true}>Applica</Radio>
                        <Radio value={false}>Non applicare</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};
const columns = [
    {
        key: "id",
        dataIndex: "id",
        width: 70,
        ellipsis: true,
        title: "ID",
        render: (x) => withTooltip(x),
    },
    {
        title: "Categoria",
        dataIndex: ["category", "name"],
        width: 100,
        ellipsis: true,
        key: "category",
        render: (x) => withTooltip(x),
    },
    {
        title: "Etichetta",
        dataIndex: "label",
        width: 100,
        ellipsis: true,
        key: "label",
        render: (x) => withTooltip(x),
    },
    {
        title: "Codice articolo",
        dataIndex: "refId",
        width: 100,
        ellipsis: true,
        key: "refId",
        render: (x) => withTooltip(x),
    },
    {
        title: "Descrizione",
        dataIndex: "description",
        width: 200,
        ellipsis: true,
        key: "description",
        render: (x) => withTooltip(x),
    },
    {
        title: "U.M.",
        dataIndex: "measureUnit",
        width: 200,
        ellipsis: true,
        key: "measureUnit",
        render: (x) => withTooltip(x),
    },
    {
        title: "Prezzo base/Punti",
        dataIndex: "basePrice",
        width: 100,
        ellipsis: true,
        render: (x) => x.toLocaleString("it-IT",{minimumFractionDigits: 2}),
        key: "basePrice",
    },
    {
        title: "Applica coefficiente",
        dataIndex: "applyMultiplier",
        width: 50,
        ellipsis: true,
        key: "applyMultiplier",
        render: (x) => (x ? "Si" : "No"),
    },
];

export default function Items() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedItem, setSelectedItem] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [newItemModalOpen, setNewItemModalOpen] = useState(false);
    const user = useContext(UserContext);

    const loadData = () => {
        setLoading(true);
        const params = [...searchParams.entries()].reduce(
            (acc, [k, v]) => ({ ...acc, [k]: v }),
            {},
        );
        axiosInstance
            .get("item", { params })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setSelectedRowKeys([]);
        loadData();
    }, [searchParams]);

    const page = searchParams.get("page") ?? "0";
    const pageSize = searchParams.get("pageSize") ?? "10";
    return (
        <>
            <NewItemModal
                visible={newItemModalOpen}
                onCancel={() => setNewItemModalOpen(false)}
                onOk={() => {
                    loadData();
                    setNewItemModalOpen(false);
                }}
            />
            <EditItemModal
                id={selectedItem}
                onCancel={() => setSelectedItem()}
                onOk={() => {
                    loadData();
                    setSelectedItem();
                }}
            />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <Row>
                    <Col span={12}>
                        <BackTitle backEnabled={false} title={"Articoli e servizi"} padding={0} />
                    </Col>
                </Row>
                <Toolbar
                    selectedKeys={selectedRowKeys}
                    addAction={
                        user.permissions?.includes("item.create") &&
                        (() => setNewItemModalOpen(true))
                    }
                    editAction={
                        user.permissions?.includes("item.update") && ((key) => setSelectedItem(key))
                    }
                    deleteAction={
                        user.permissions?.includes("item.delete") &&
                        ((keys) => {
                            axiosInstance
                                .delete(`item?${keys.map((x) => "ids=" + x).join("&")}`)
                                .then(loadData);
                        })
                    }
                />
                <Table
                    size="small"
                    loading={loading}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }}
                    columns={columns}
                    tableLayout={"fixed"}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                setSelectedRowKeys([record.key]);
                            }, // click row
                        };
                    }}
                    scroll={{ x: columns.reduce((acc, x) => acc + x.width, 0) }}
                    dataSource={data.data?.map((x) => ({ ...x, key: x.id })) ?? []}
                    onChange={(pagination) => {
                        searchParams.set("page", "" + (pagination.current - 1));
                        searchParams.set("pageSize", "" + pagination.pageSize);
                        setSearchParams(searchParams, { replace: true });
                    }}
                    pagination={{
                        current: Number(page) + 1,
                        showTotal: (total) => "Totale: " + total,
                        pageSize: Number(pageSize),
                        total: data.count ?? 0,
                    }}
                />
            </div>
        </>
    );
}
