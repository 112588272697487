import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import axiosInstance from "../api";
import { Checkbox, Form, Input, InputNumber, message, Modal, Select, Spin } from "antd";
import { filterOption, requiredField } from "../util";
import { AccountingContext } from "./AccountingDetails";
import { PlusOutlined } from "@ant-design/icons";
import { UserContext } from "../App";

export default function EditSectionItem({ open, itemId, onFinish, onCancel }) {
    const accounting = useContext(AccountingContext);
    const [initLoading, setInitLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isExtra, setIsExtra] = useState(false);
    const [contractItems, setContractItems] = useState([]);
    const [teams, setTeams] = useState([]);
    const user = useContext(UserContext);
    const [form] = useForm();
    const [sections, setSections] = useState([]);
    const [sectionSearchVal, setSectionSearchVal] = useState();
    const [sectionSaveLoading, setSectionSaveLoading] = useState(false);
    const { orderId } = useParams();

    useEffect(() => {
        if (accounting.order?.contract.id)
            axiosInstance
                .get(`contractItem/compact`, {
                    params: { contract: accounting.order?.contract.id },
                })
                .then(({ data }) => setContractItems(data));
    }, [accounting.order?.contract.id]);

    useEffect(() => {
        if (open) {
            axiosInstance
                .get(`section`, {
                    params: { order: orderId },
                })
                .then(({ data }) => {
                    setSections(data);
                });
            axiosInstance
                .get("team")
                .then(({ data }) => setTeams(data.map((x) => ({ label: x.name, value: x.id }))));
        }
    }, [open]);

    useEffect(() => {
        if (itemId && open) {
            document.body.style.overflowY = "scroll";
            setInitLoading(true);
            axiosInstance
                .get(`/accountingItem/${itemId}`)
                .then(({ data }) => {
                    form.setFieldsValue({
                        ...data,
                        isExtra: data.type === "EXTRA",
                        section: data.section?.id,
                        teamId: data.team?.id,
                        contractItem: data.contractItem?.id,
                    });
                    setIsExtra(form.getFieldValue("isExtra"));
                })
                .finally(() => setInitLoading(false));
        }
    }, [itemId, open]);

    const onFormFinish = (form) => {
        setLoading(true);
        axiosInstance
            .put(`accountingItem/${itemId}`, form)
            .then(() => {
                message.success("Elemento modificato");
            })
            .then(() => onFinish?.(form))
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={{ form: "editSectionItem", htmlType: "submit", loading: loading }}
            title={"Modifica voce"}
            okText={"Salva"}
        >
            <Spin spinning={initLoading}>
                <div>
                    <Form
                        id={"editSectionItem"}
                        form={form}
                        onFinish={onFormFinish}
                        layout={"vertical"}
                        style={{ marginTop: 16 }}
                    >
                        <Form.Item label={"Tipo"} name={"isExtra"} valuePropName={"checked"}>
                            <Checkbox
                                checked={isExtra}
                                onChange={({ target }) => setIsExtra(target.checked)}
                            >
                                Voce extra listino
                            </Checkbox>
                        </Form.Item>
                        {!isExtra && (
                            <Form.Item label={"Voce"} name={"contractItem"} rules={[requiredField]}>
                                <Select
                                    filterOption={filterOption}
                                    showSearch
                                    options={contractItems.map((x) => ({
                                        label: x.refId + " - " + x.description,
                                        value: x.id,
                                    }))}
                                />
                            </Form.Item>
                        )}
                        {isExtra && (
                            <Form.Item
                                label={"Descrizione"}
                                name={"description"}
                                rules={[requiredField]}
                            >
                                <Input />
                            </Form.Item>
                        )}
                        <Form.Item label={"Tratta (Opzionale)"} name={"section"}>
                            <Select
                                filterOption={filterOption}
                                onSearch={setSectionSearchVal}
                                allowClear
                                notFoundContent={
                                    sections.length == 0 &&
                                    sectionSearchVal &&
                                    user.permissions?.includes("section.create") && (
                                        <Spin spinning={sectionSaveLoading}>
                                            <div
                                                style={{ color: "black", cursor: "pointer" }}
                                                onClick={() => {
                                                    const data = {
                                                        type: "NODE",
                                                        node: sectionSearchVal,
                                                        orderId: orderId,
                                                    };
                                                    setSectionSaveLoading(true);
                                                    axiosInstance
                                                        .post("section", data)
                                                        .then(({ data }) => {
                                                            setSections([...sections, data]);
                                                            setSectionSearchVal();
                                                            setSectionSaveLoading(false);
                                                        });
                                                }}
                                            >
                                                <PlusOutlined /> Clicca per creare tratta "
                                                {sectionSearchVal}"
                                            </div>
                                        </Spin>
                                    )
                                }
                                showSearch
                                options={sections.map((x) => ({ label: x.name, value: x.id }))}
                            />
                        </Form.Item>
                        <Form.Item label={"Quantità"} name={"quantity"} rules={[requiredField]}>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label={"Note"} name={"notes"}>
                            <Input />
                        </Form.Item>

                        <Form.Item label={"Squadra"} name={"teamId"} rules={[requiredField]}>
                            <Select
                                allowClear
                                showSearch
                                filterOption={filterOption}
                                options={teams}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        </Modal>
    );
}
