import { useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../api";
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table,
} from "antd";
import { useParams, useSearchParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { filterOption, includesIgnoreCase, requiredField, withTooltip } from "../util";
import Toolbar from "../common/Toolbar";
import { UserContext } from "../App";
import Search from "antd/lib/input/Search";

const NewSectionModal = ({ onOk, onCancel, visible }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const {orderId} = useParams()
    const ref = useRef(null)

    useEffect(() => {
        document.body.style.overflowY = "scroll";
    }, []);

    return (
        <Modal
            title="Nuova tratta"
            open={visible}
            afterOpenChange={open => open && ref.current.focus()}
            okButtonProps={{ htmlType: "submit", form: "newSectionForm" }}
            onCancel={() => {
                form.resetFields();
                onCancel?.();
            }}
        >
            <Form
                form={form}
                id="newSectionForm"
                layout="vertical"
                onFinish={(data) => {
                    setLoading(true);
                    axiosInstance
                        .post("section", {orderId, type: 'NODE', node: data.name})
                        .then(() => {
                            onOk?.();
                        })
                        .then(() => form.resetFields());
                }}
            >
                <Form.Item label={"Nome"} name={"name"}>
                    <Input ref={ref} />
                </Form.Item>
            </Form>
        </Modal>
    );
};


const columns = [
    {
        key: 'id',
        dataIndex: 'id',
        width: 100,
        title: 'ID'
    },
    {
        key: 'name',
        dataIndex: 'name',
        width: 400,
        title: 'Nome'
    },
]


export default function Sections() {
    const [data, setData] = useState([]);
    const {orderId} = useParams()
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [newSectionModalOpen, setNewSectionModalOpen] = useState(false);
    const user = useContext(UserContext);
    const [searchVal, setSearchVal] = useState()

    const loadData = () => {
        setLoading(true);
        axiosInstance
            .get("section", { params : {order: orderId}})
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setSelectedRowKeys([]);
        loadData();
    }, [searchParams]);

    return (
        <>
            <NewSectionModal
                visible={newSectionModalOpen}
                onCancel={() => setNewSectionModalOpen(false)}
                onOk={() => {
                    loadData();
                    setNewSectionModalOpen(false);
                }}
            />
            <Search style={{width: 200, marginBottom: 20}} onSearch={setSearchVal} placeholder="Filtra tratta" allowClear/>
                <Toolbar
                    selectedKeys={selectedRowKeys}
                    addAction={
                        user.permissions?.includes("section.create") &&
                        (() => setNewSectionModalOpen(true))
                    }
                    deleteAction={
                        user.permissions?.includes("section.delete") &&
                        ((keys) => {
                            axiosInstance
                                .delete(`section?${keys.map((x) => "ids=" + x).join("&")}`)
                                .then(loadData);
                        })
                    }
                />
                <Table
                    size="small"
                    loading={loading}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }}
                    columns={columns}
                    tableLayout={"fixed"}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                setSelectedRowKeys([record.id]);
                            }, // click row
                        };
                    }}
                    scroll={{ x: columns.reduce((acc, x) => acc + x.width, 0) }}
                    dataSource={data.filter(x => !searchVal || includesIgnoreCase(x.name,searchVal))}
                />
        </>
    );
}
