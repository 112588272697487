import { Button, Col, Input, List, Popover, Row, Space, Table, Tooltip, Typography } from "antd";
import { useParams } from "react-router-dom";
import {
    DownloadOutlined,
    InfoCircleFilled,
    InfoCircleTwoTone,
    PlusOutlined,
    QuestionCircleFilled,
    QuestionCircleOutlined,
    QuestionCircleTwoTone,
    UploadOutlined,
    UserAddOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import Toolbar from "../common/Toolbar";
import axiosInstance from "../api";
import { downloadFile, includesIgnoreCase, withTooltip } from "../util";
import NewSectionItem from "./NewSectionItem";
import EditSectionItem from "./EditSectionItem";

const columns = [
    {
        key: "refId",
        dataIndex: "contractItem",
        title: "Voce",
        width: 120,
        fixed: true,
        ellipsis: true,
        render: (le) => withTooltip(le?.refId ?? "EXTRA"),
    },
    {
        key: "description",
        dataIndex: "description",
        title: "Descrizione",
        width: 250,
        ellipsis: true,
        render: (desc) => withTooltip(desc),
    },
    {
        key: "quantity",
        dataIndex: "quantity",
        align: "end",
        width: 70,
        ellipsis: true,
        title: "Quantità",
    },
    {
        key: "notes",
        dataIndex: "notes",
        title: "Note",
        width: 170,
        render: (x) => withTooltip(x),
        ellipsis: true,
    },
    {
        key: "team",
        dataIndex: "team",
        title: "Squadra",
        width: 150,
        render: (x) => withTooltip(x?.name),
        ellipsis: true,
    },
];

export default function OrderAccountingSectionItems({ style }) {
    const { orderId, accountingId } = useParams();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editItemOpen, setEditItemOpen] = useState(false);
    const [newItemOpen, setNewItemOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [data, setData] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState();
    const [searchValue, setSearchValue] = useState();

    const tableRef = useRef(null);

    const deleteSelectedRecords = (keys) => {
        setLoading(true);
        axiosInstance
            .delete(`/accountingItem?${keys.map((x) => `ids=${x}`).join("&")}`)
            .then(() => setSelectedRowKeys([]))
            .then(() => fetch())
            .finally(() => setLoading(false));
    };

    const fetch = () => {
        setLoading(true);
        axiosInstance
            .get("section", { params: { order: orderId, pageSize: 99999, page: 0 } })
            .then(({ data }) =>
                setSections([
                    { name: "NO TRATTA", id: -1 },
                    ...data.sort((a, b) => a.name.localeCompare(b.name)),
                ]),
            );

        axiosInstance
            .get("accountingItem", { params: { accountingId, pageSize: 99999, page: 0 } })
            .then(({ data }) => {
                setData(data);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!selectedSection) {
            setSelectedSection(sections[0]?.id);
        }
    }, [sections]);

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        const tableItems = !!data
            ? data.map((x) => ({ ...x, key: x.id })).sort((a, b) => a.id - b.id)
            : [];
        const scroll = tableItems.length > items.length;
        setItems(tableItems);
        setScroll(scroll);
    }, [data]);

    useEffect(() => {
        if (scroll) {
            tableRef.current.scrollTo({ index: items.length - 1 });
            setScroll(false);
        }
    }, [scroll]);

    useEffect(() => {
        setSelectedRowKeys([]);
    }, [selectedSection]);

    return (
        <div {...{ style }}>
            <EditSectionItem
                open={editItemOpen}
                onFinish={(form) => {
                    fetch();
                    setEditItemOpen(false);
                    setSelectedSection(form.section ?? -1);
                    setSelectedRowKeys([]);
                }}
                itemId={items.filter((x) => x.id === selectedRowKeys[0])[0]?.id}
                onCancel={() => setEditItemOpen(false)}
            />
            <Row gutter={[16, 16]} >
                <Col span={4} style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                    <div>
                        <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>Tratte</span>{" "}
                        <Tooltip
                            title={
                                'Seleziona una tratta per visualizzare e/o inserire nuove righe su quella tratta. Durante l\'inserimento di nuove righe, il campo "Tratta" verra compilato in automatico in base alla tratta selezionata'
                            }
                            placement={"right"}
                        >
                            <QuestionCircleTwoTone style={{ marginLeft: 10 }} />
                        </Tooltip>
                    </div>
                    <Row>
                        <Col flex={"auto"}>
                            <Input
                                allowClear
                                placeholder="Filtra tratta"
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <List
                        dataSource={sections.filter((x) =>
                            includesIgnoreCase(x.name ?? "", searchValue ?? ""),
                        )}
                        style={{ height: 500, overflow: "auto" }}
                        renderItem={(item) => (
                            <List.Item
                                className={
                                    selectedSection === item.id
                                        ? "section-selected"
                                        : "section-unselected"
                                }
                                style={{
                                    cursor: "pointer",
                                    padding: "3px 2px",
                                }}
                                onClick={() => setSelectedSection(item.id)}
                            >
                                <div
                                    style={{
                                        overflow: "hidden",
                                        fontSize: "0.95em",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {item.name}
                                </div>
                            </List.Item>
                        )}
                    />
                    <Button type="dashed" icon={<PlusOutlined />}>
                        Crea nuova tratta
                    </Button>
                </Col>
                <Col span={20}>
                    <div style={{ width: "100%", textAlign: "end" }}>
                        <span>
                            Tratta selezionata<br/>
                            <b style={{fontSize: '1.2em'}}>
                                <u>{sections.filter((x) => x.id === selectedSection)[0]?.name}</u>
                            </b>
                        </span>
                    </div>
                    <Toolbar
                        topDivider={false}
                        selectedKeys={selectedRowKeys}
                        addAction={() => {}}
                        addButton={
                            <Popover
                                trigger={"click"}
                                open={newItemOpen}
                                onOpenChange={setNewItemOpen}
                                placement={"right"}
                                destroyTooltipOnHide
                                content={
                                    <NewSectionItem
                                        open={newItemOpen}
                                        section={selectedSection !== -1 ? selectedSection : null}
                                        onOk={(form) => {
                                            fetch();
                                            setNewItemOpen(false);
                                            setSelectedRowKeys([]);
                                            setSelectedSection(form.section ?? -1);
                                        }}
                                    />
                                }
                            >
                                <Button type={"primary"} icon={<PlusOutlined />}>
                                    Nuova riga
                                </Button>
                            </Popover>
                        }
                        editAction={(key) => {
                            setEditItemOpen(true);
                        }}
                        deleteAction={deleteSelectedRecords}
                    />
                    <Table
                        loading={loading}
                        size={"small"}
                        scroll={{ x: columns.reduce((acc, x) => x.width + acc, 0), y: 500 }}
                        tableLayout={"fixed"}
                        columns={columns}
                        virtual
                        ref={tableRef}
                        pagination={false}
                        rowClassName={(record) =>
                            record.id === selectedRowKeys[0] ? "scroll-row" : ""
                        }
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setSelectedRowKeys([record.key]);
                                }, // click row
                            };
                        }}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: setSelectedRowKeys,
                            columnWidth: 60,
                        }}
                        dataSource={items.filter(
                            (x) =>
                                selectedSection === x.section?.id ||
                                (selectedSection === -1 && !x.section),
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
}
