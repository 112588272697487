import {useParams} from "react-router-dom";
import {Descriptions, Spin, Tabs} from "antd";
import ContractMultipliers from "./multiplier/ContractMultipliers";
import Discounts from "./Discounts";
import BackTitle from "../../common/BackTitle";
import {useContext, useEffect, useState} from "react";
import axiosInstance from "../../api";
import { UserContext } from "../../App";
import ContractItems from "../../contractitem/ContractItems";


export default function ContractDetails() {
    const {customerId, contractId} = useParams()
    const [loading, setLoading] = useState(false);
    const [contract, setContract] = useState({})
    const user = useContext(UserContext)

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`customer/${customerId}/contract/${contractId}`)
            .then(({data}) => setContract(data))
            .finally(() => setLoading(false))
    }, []);

    return <Spin spinning={loading}>
        <div style={{display: "flex", flexDirection: "column", rowGap: 16}}>
            <BackTitle padding={0} title={`Dettaglio contratto: ${contract.name}`}/>
            <Tabs
                items={[
                    {
                        key: 1,
                        label: 'Coefficienti',
                        permission: 'multiplier.read',
                        children: <ContractMultipliers/>
                    },
                    {
                        key: 2,
                        label: 'Listino',
                        permission: 'contractItem.read',
                        children: <ContractItems/>
                    }
                ].filter(x => user.permissions?.includes(x.permission))}
            />
        </div>
    </Spin>
}
