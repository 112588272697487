import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Switch,
    Table,
} from "antd";
import { useParams, useSearchParams } from "react-router-dom";
import BackTitle from "../common/BackTitle";
import { filterOption, requiredField, withTooltip } from "../util";
import Toolbar from "../common/Toolbar";
import { UserContext } from "../App";

const NewContractItemModal = ({ onOk, onCancel, visible }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { contractId } = useParams();

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (visible)
            axiosInstance
                .get("item", { params: { notInContract: contractId, pageSize: 999999 } })
                .then(({ data }) => setItems(data.data));
    }, [visible]);

    useEffect(() => {
        document.body.style.overflowY = "scroll";
    }, []);

    return (
        <Modal
            title="Nuovo articolo di contratto"
            open={visible}
            okButtonProps={{ htmlType: "submit", loading, form: "newContractItemForm" }}
            onCancel={() => {
                form.resetFields();
                onCancel?.();
            }}
        >
            <Form
                form={form}
                id="newContractItemForm"
                layout="vertical"
                onFinish={(data) => {
                    setLoading(true);
                    axiosInstance
                        .post("contractItem", [
                            { ...data, discount: data.discount / 100, contract: contractId },
                        ])
                        .then(() => {
                            onOk?.();
                        })
                        .then(() => form.resetFields())
                        .finally(() => setLoading(false));
                }}
            >
                <Form.Item label={"Articolo"} name={"item"} rules={[requiredField]}>
                    <Select
                        showSearch
                        filterOption={filterOption}
                        options={items.map((x) => ({
                            label: `${x.refId} ${x.description}`,
                            value: x.id,
                        }))}
                    />
                </Form.Item>
                <Form.Item label={"Prezzo base/Punti"} name={"basePrice"} rules={[requiredField]}>
                    <InputNumber min={0} precision={2} decimalSeparator={","} />
                </Form.Item>

                <Form.Item label={"Sconto"} name={"discount"} rules={[requiredField]}>
                    <InputNumber min={0} precision={2} decimalSeparator={","} suffix="%" />
                </Form.Item>
                <Form.Item
                    label={"Applica coefficiente"}
                    name={"applyMultiplier"}
                    rules={[requiredField]}
                >
                    <Radio.Group>
                        <Radio value={true}>Applica</Radio>
                        <Radio value={false}>Non applicare</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const EditContractItemModal = ({ id, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) {
            document.body.style.overflowY = "scroll";
            setDataLoading(true);
            axiosInstance
                .get(`contractItem/${id}`)
                .then(({ data }) => form.setFieldsValue({ ...data, discount: data.discount * 100 }))
                .finally(() => setDataLoading(false));
        } else form.resetFields();
    }, [id]);

    const item = form.getFieldValue("item");
    const itemDesc = `${item?.refId} ${item?.description}`;

    return (
        <Modal
            title="Modifica articolo"
            open={!!id}
            okButtonProps={{ htmlType: "submit", form: "editContractItemForm" }}
            onCancel={() => {
                form.resetFields();
                onCancel?.();
            }}
        >
            <Form
                form={form}
                id="editContractItemForm"
                layout="vertical"
                onFinish={(data) => {
                    axiosInstance
                        .put(`contractItem/${id}`, { ...data, discount: data.discount / 100 })
                        .then(() => {
                            onOk?.();
                        })
                        .then(() => form.resetFields());
                }}
            >
                <Form.Item label={"Articolo"}>
                    <Input disabled value={itemDesc} />
                </Form.Item>
                <Form.Item label={"Prezzo base/Punti"} name={"basePrice"} rules={[requiredField]}>
                    <InputNumber min={0} precision={2} decimalSeparator={","} />
                </Form.Item>

                <Form.Item label={"Sconto"} name={"discount"} rules={[requiredField]}>
                    <InputNumber min={0} suffix={"%"} precision={2} decimalSeparator={","} />
                </Form.Item>
                <Form.Item label={"Coefficiente"} name={"applyMultiplier"} rules={[requiredField]}>
                    <Radio.Group>
                        <Radio value={true}>Applica</Radio>
                        <Radio value={false}>Non applicare</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};
const columns = [
    {
        key: "id",
        dataIndex: "id",
        width: 70,
        ellipsis: true,
        title: "ID",
        render: (x) => withTooltip(x),
    },
    {
        title: "Codice articolo",
        dataIndex: ["item", "refId"],
        width: 100,
        ellipsis: true,
        key: "refId",
        render: (x) => withTooltip(x),
    },
    {
        title: "Descrizione",
        dataIndex: ["item", "description"],
        width: 150,
        ellipsis: true,
        key: "description",
        render: (x) => withTooltip(x),
    },
    {
        title: "Prezzo base/Punti",
        dataIndex: "basePrice",
        align: "end",
        width: 80,
        ellipsis: true,
        render: (x) => x.toLocaleString("it-IT", { minimumFractionDigits: 2 }),
        key: "basePrice",
    },
    {
        title: "Sconto (%)",
        dataIndex: "discount",
        width: 80,
        ellipsis: true,
        align: "end",
        key: "discount",
        render: (x) => (x * 100).toLocaleString("it-IT", { minimumFractionDigits: 2 }),
    },
    {
        title: "Applica coefficiente",
        dataIndex: "applyMultiplier",
        width: 70,
        align: "end",
        ellipsis: true,
        key: "applyMultiplier",
        render: (x) => (x ? "Si" : "No"),
    },
    {
        title: "Attivo",
        dataIndex: "active",
        align: "end",
        width: 40,
        ellipsis: true,
        key: "active",
        render: (x) => (x ? "Si" : "No"),
    },
];

export default function ContractItems() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { contractId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedItem, setSelectedItem] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [newContractItemModalOpen, setNewContractItemModalOpen] = useState(false);
    const user = useContext(UserContext);

    const loadData = () => {
        setLoading(true);
        const params = [...searchParams.entries()].reduce(
            (acc, [k, v]) => ({ ...acc, [k]: v }),
            {},
        );
        axiosInstance
            .get("contractItem", { params: { ...params, contract: contractId } })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        setSelectedRowKeys([]);
        loadData();
    }, [searchParams]);

    const page = searchParams.get("page") ?? "0";
    const pageSize = searchParams.get("pageSize") ?? "10";
    return (
        <>
            <NewContractItemModal
                visible={newContractItemModalOpen}
                onCancel={() => setNewContractItemModalOpen(false)}
                onOk={() => {
                    loadData();
                    setNewContractItemModalOpen(false);
                }}
            />
            <EditContractItemModal
                id={selectedItem}
                onCancel={() => setSelectedItem()}
                onOk={() => {
                    loadData();
                    setSelectedItem();
                }}
            />
            <div>
                <Toolbar
                    selectedKeys={selectedRowKeys}
                    addAction={
                        user.permissions?.includes("item.create") &&
                        (() => setNewContractItemModalOpen(true))
                    }
                    editAction={
                        user.permissions?.includes("item.update") && ((key) => setSelectedItem(key))
                    }
                    deleteAction={
                        user.permissions?.includes("item.delete") &&
                        ((keys) => {
                            axiosInstance
                                .delete(`contractItem?${keys.map((x) => "ids=" + x).join("&")}`)
                                .then(loadData);
                        })
                    }
                />
                <Table
                    size="small"
                    loading={loading}
                    rowKey="id"
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }}
                    columns={columns}
                    tableLayout={"fixed"}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                setSelectedRowKeys([record.id]);
                            }, // click row
                        };
                    }}
                    scroll={{ x: columns.reduce((acc, x) => acc + x.width, 0) }}
                    dataSource={data.data ?? []}
                    onChange={(pagination) => {
                        searchParams.set("page", "" + (pagination.current - 1));
                        searchParams.set("pageSize", "" + pagination.pageSize);
                        setSearchParams(searchParams, { replace: true });
                    }}
                    pagination={{
                        current: Number(page) + 1,
                        showTotal: (total) => "Totale: " + total,
                        pageSize: Number(pageSize),
                        total: data.count ?? 0,
                    }}
                />
            </div>
        </>
    );
}
