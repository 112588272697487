import { message, Table } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../api";
import Toolbar from "../common/Toolbar";
import NewInvoicedAccountingItem from "./NewInvoicedAccountingItem";
import dayjs from "dayjs";
import { useHotkeys } from "react-hotkeys-hook";
import { UserContext } from "../App";
import UpdateInvoicedAccountingItem from "./UpdateInvoicedAccountingItem";
const columns = [
    {
        key: "month",
        dataIndex: "month",
        ellipsis: true,
        title: "Competenza",
        render: (x) => dayjs(x).format("YYYY/MM"),
    },
    {
        key: "refId",
        dataIndex: ["contractItem", "refId"],
        ellipsis: true,
        title: "Voce",
    },
    {
        key: "description",
        dataIndex: ["contractItem", "description"],
        ellipsis: true,
        title: "Descrizione",
    },
    {
        key: "quantity",
        dataIndex: "quantity",
        ellipsis: true,
        title: "Quantità",
    },
    {
        key: "notes",
        dataIndex: "notes",
        ellipsis: true,
        title: "Note",
    },
    {
        key: "price",
        dataIndex: "totalPrice",
        ellipsis: true,
        align: "right",
        title: "Importo",
        render: (x) => x.toLocaleString("it-IT", { minimumFractionDigits: 2 }),
    },
];

export default function ({ order }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newItemOpen, setNewItemOpen] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const tableRef = useRef();
    const [selectedUpdateItem, setSelectedUpdateItem] = useState();
    const user = useContext(UserContext);
    useHotkeys("n", () => setNewItemOpen(true));

    useEffect(() => {
        if (order) {
            setLoading(true);
            axiosInstance
                .get("invoicedAccountingItem", { params: { order } })
                .then(({ data }) => setData(data))
                .finally(() => setLoading(false));
        }
    }, [order]);

    useEffect(() => {
        tableRef.current.scrollTo({ index: data.length - 1 });
    }, [data]);

    const deleteRecords = (keys) => {
        setLoading(true);
        axiosInstance
            .delete(`invoicedAccountingItem?${keys.map((x) => `id=${x}`).join("&")}`)
            .then(() => setSelectedKeys([]))
            .then(() => setData(data.filter((x) => !keys.includes(x.id))))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <NewInvoicedAccountingItem
                open={newItemOpen}
                onOk={(item) => {
                    message.success("Elemento salvato con successo");
                    setNewItemOpen(false);
                    setData([...data, item]);
                }}
                onCancel={() => setNewItemOpen(false)}
            />
            <UpdateInvoicedAccountingItem
                item={selectedUpdateItem}
                onOk={(item) => {
                    message.success("Elemento modificato con successo");
                    setSelectedUpdateItem();
                    setData(data.map((x) => (x.id === item.id ? { ...item, key: item.id } : x)));
                }}
                onCancel={() => setSelectedUpdateItem()}
            />
            <Toolbar
                selectedKeys={selectedKeys}
                editAction={
                    user.permissions?.includes("invoicedAccountingItem.update") &&
                    ((id) => setSelectedUpdateItem(data.filter((x) => x.id === id)[0]))
                }
                addAction={
                    user.permissions?.includes("invoicedAccountingItem.create") &&
                    (() => setNewItemOpen(true))
                }
                deleteAction={
                    user.permissions?.includes("invoicedAccountingItem.delete") && deleteRecords
                }
            />
            <Table
                ref={tableRef}
                loading={loading}
                dataSource={data.map((x) => ({ ...x, key: x.id })).sort((a, b) => a.id - b.id)}
                scroll={{ y: 500 }}
                rowSelection={{ selectedRowKeys: selectedKeys, onChange: setSelectedKeys }}
                tableLayout="fixed"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            setSelectedKeys([record.key]);
                        }, // click row
                    };
                }}
                pagination={false}
                size="small"
                columns={columns}
            ></Table>
        </>
    );
}
