import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Spin,
} from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import axiosInstance from "../api";
import { useParams, useSearchParams } from "react-router-dom";
import { filterOption, requiredField } from "../util";
import { useForm } from "antd/lib/form/Form";
import { AccountingContext } from "./AccountingDetails";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { UserContext } from "../App";

export default function NewSectionItem({ section, open, onOk }) {
    const { orderId } = useParams();
    const [contractItems, setContractItems] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isExtra, setIsExtra] = useState(false);
    const [form] = useForm();
    const ref = useRef(null);
    const [sections, setSections] = useState([]);
    const [sectionSearchVal, setSectionSearchVal] = useState();
    const [sectionSaveLoading, setSectionSaveLoading] = useState(false);
    const [supplies, setSupplies] = useState([]);
    const user = useContext(UserContext);

    const accounting = useContext(AccountingContext);
    const selectedItem = Form.useWatch("contractItem", form);

    useEffect(() => {
        if (selectedItem)
            axiosInstance
                .get(`contractItem/${selectedItem}/supply`)
                .then(({ data }) => setSupplies(data))
                .then(() =>
                    form.setFieldValue("supplies", [{ contractItem: null, quantity: null }]),
                );
    }, [selectedItem]);

    useEffect(() => {
        if(open){
            axiosInstance
                .get(`contractItem/compact`, {
                    params: { contract: accounting.order.contract.id },
                })
                .then(({ data }) => setContractItems(data));

            axiosInstance
                .get(`section`, {
                    params: { order: orderId },
                })
                .then(({ data }) => {
                    setSections(data);
                });
            axiosInstance
                .get("team")
                .then(({ data }) => setTeams(data.map((x) => ({ label: x.name, value: x.id }))));
            form.setFieldValue('section',section)
        }else
            form.resetFields()
    },[open])

    useEffect(() => {
        if (accounting.id) {
            if (ref) {
                ref.current.focus();
            }
        }
    }, [accounting]);

    const onFinish = (form) => {
        setLoading(true);
        axiosInstance
            .post(`accountingItem`, { ...form, accountingId: accounting.id })
            .then(() => {
                message.success("Elemento salvato");
            })
            .then(() => onOk?.(form))
            .finally(() => setLoading(false));
    };

    return (
        <Form
            onFinish={onFinish}
            className={"popover-form"}
            form={form}
            layout={"vertical"}
            style={{ width: 500, maxHeight: 800, overflowY: "auto", overflowX: "hidden" }}
        >
            <Form.Item label={"Tratta"} name={"section"}>
                <Select
                    filterOption={filterOption}
                    onSearch={setSectionSearchVal}
                    allowClear
                    notFoundContent={
                        sectionSearchVal &&
                        user.permissions?.includes("section.create") && (
                            <Spin spinning={sectionSaveLoading}>
                                <div
                                    style={{ color: "black", cursor: "pointer" }}
                                    onClick={() => {
                                        const data = {
                                            type: "NODE",
                                            node: sectionSearchVal,
                                            orderId: orderId,
                                        };
                                        setSectionSaveLoading(true);
                                        axiosInstance.post("section", data).then(({ data }) => {
                                            setSections([...sections, data]);
                                            setSectionSearchVal();
                                            setSectionSaveLoading(false);
                                        });
                                    }}
                                >
                                    <PlusOutlined /> Clicca per creare tratta "{sectionSearchVal}"
                                </div>
                            </Spin>
                        )
                    }
                    showSearch
                    options={sections.map((x) => ({ label: x.name, value: x.id }))}
                />
            </Form.Item>
            <Form.Item name={"isExtra"} valuePropName={"checked"} label={"Tipo"}>
                <Checkbox checked={isExtra} onChange={({ target }) => setIsExtra(target.checked)}>
                    Voce extra listino
                </Checkbox>
            </Form.Item>
            {!isExtra && (
                <Form.Item label={"Voce"} name={"contractItem"} rules={[requiredField]}>
                    <Select
                        ref={ref}
                        showSearch
                        filterOption={filterOption}
                        options={contractItems.map((x) => ({
                            label: x.refId + " - " + x.description,
                            value: x.id,
                        }))}
                    />
                </Form.Item>
            )}
            {isExtra && (
                <Form.Item label={"Descrizione"} name={"description"} rules={[requiredField]}>
                    <Input />
                </Form.Item>
            )}
            <Form.Item label={"Quantità"} name={"quantity"} rules={[requiredField]}>
                <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label={"Note"} name={"notes"}>
                <Input />
            </Form.Item>
            <Form.Item label={"Squadra"} name={"teamId"} rules={[requiredField]}>
                <Select allowClear showSearch filterOption={filterOption} options={teams} />
            </Form.Item>
            {supplies.length > 0 && (
                <Form.List name="supplies" rules={[requiredField]}>
                    {(fields, { add, remove }) => (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 8,
                                marginBottom: 24,
                            }}
                        >
                            <Divider style={{ margin: 2 }} />
                            <b style={{ fontSize: "1.2em" }}>Fornitura</b>
                            {fields.map((field) => (
                                <Row gutter={[8, 8]}>
                                    <Col span={16}>
                                        <Form.Item
                                            label={field.name === 0 ? "Voce" : null}
                                            style={{ margin: 0 }}
                                            rules={[requiredField]}
                                            name={[field.name, "contractItem"]}
                                        >
                                            <Select
                                                showSearch
                                                filterOption={filterOption}
                                                style={{ minWidth: "100%" }}
                                                options={supplies.map((x) => ({
                                                    label: x.refId + " - " + x.description,
                                                    value: x.id,
                                                }))}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label={field.name === 0 ? "Quantità" : null}
                                            name={[field.name, "quantity"]}
                                            rules={[requiredField]}
                                        >
                                            <InputNumber style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        {field.name > 0 && (
                                            <Form.Item
                                                style={{ margin: 0 }}
                                                label={field.name === 0 ? " " : null}
                                            >
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                    type="text"
                                                    danger
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                            ))}
                            <Button type="dashed" onClick={() => add()} block>
                                + Aggiungi nuova riga fornitura
                            </Button>
                        </div>
                    )}
                </Form.List>
            )}

            <Form.Item style={{ textAlign: "end", marginBottom: 0 }}>
                <Button htmlType={"submit"} loading={loading} type={"primary"}>
                    Salva
                </Button>
            </Form.Item>
        </Form>
    );
}
