import { Col, Input, Row, Spin, Table, Tree, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { ApiOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import PermissionRequired from "../common/PermissionRequired";
import { withTooltip } from "../util";
const { Text } = Typography;

export default function ReportTable2({ report, refresh }) {
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [searchText, setSearchText] = useState();
    const [refIdSearchText, setRefIdSearchText] = useState();

    const filteredLines = useMemo(
        () =>
            (report.lines ?? []).filter(
                (x) =>
                    (!searchText ||
                        x.section?.name.toLowerCase().includes(searchText.toLowerCase())) &&
                    (!refIdSearchText ||
                        (x.contractItem.refId + x.description)
                            .toLowerCase()
                            .includes(refIdSearchText.toLowerCase())),
            ),
        [report, searchText, refIdSearchText],
    );

    useEffect(() => {
        const data = filteredLines;

        const m2 = data
            .map((x) => ({
                ...x,
                key: x.id,
                title: (
                    <Row>
                        <Col flex={"700px"}>
                            {x.supplyOfId && <ApiOutlined />}{" "}
                            {withTooltip(
                                <Text ellipsis style={{ width: 550 }}>
                                    {x.contractItem?.refId ?? "EXTRA"} {x.description}
                                </Text>,
                                (x.contractItem?.refId ?? "EXTRA") + " " + x.description,
                            )}
                        </Col>
                        <Col flex={"auto"}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "end",
                                }}
                            >
                                <span style={{ width: 150, textAlign: "end" }}>
                                    {dayjs(x.accounting.month).format("MM-YYYY")}
                                </span>
                                <span style={{ width: 150, textAlign: "end" }}>
                                    {x.quantity.toLocaleString("it-IT", {
                                        minimumFractionDigits: 2,
                                    })}
                                </span>
                                {report.hasPrices && (
                                    <>
                                        <span style={{ width: 150, textAlign: "end" }}>
                                            {x.unitPrice.toLocaleString("it-IT", {
                                                minimumFractionDigits: 2,
                                            })}
                                        </span>
                                        <span style={{ width: 150, textAlign: "end" }}>
                                            {x.totalPrice.toLocaleString("it-IT", {
                                                minimumFractionDigits: 2,
                                            })}
                                        </span>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                ),
            }))
            .map((x) => ({ k: x.supplyOfId ?? x.id, v: x }))
            .reduce(
                (acc, val) => ({
                    ...acc,
                    [val.k]: [...(acc[val.k] ?? []), val.v],
                }),
                {},
            );

        const m3 = Object.values(m2)
            .map((x) => ({
                ...x.filter((x) => !x.supplyOfId)[0],
                children: x.filter((x) => !!x.supplyOfId),
            }))
            .map((x) => ({ ...x, children: x.children.length > 0 && x.children }));
        const mapped = m3.map((x) => ({
            k: x.section ?? { id: -1, name: "NESSUNA TRATTA" },
            v: x,
        }));
        const res = mapped.reduce(
            (acc, val) => ({
                ...acc,
                [val.k.id]: {
                    id: `s_${val.k.id}`,
                    key: `s_${val.k.id}`,
                    title: <b>{val.k.name}</b>,
                    isSectionRow: true,
                    name: val.k.name,
                    children: [...(acc[val.k.id]?.children ?? []), val.v],
                },
            }),
            {},
        );
        setTableData(Object.values(res));
    }, [report, searchText, refIdSearchText]);

    const keys = () =>
        report.lines && [
            ...new Set([
                ...report.lines?.map((x) => x.id),
                ...report.lines?.map((x) => `s_${x.section?.id ?? -1}`),
            ]),
        ];

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <Spin spinning={loading}>
                <div style={{ display: "flex", flexDirection: "row", columnGap: 16 }}>
                    <Input
                        allowClear
                        value={searchText}
                        onChange={({ target }) => setSearchText(target.value)}
                        style={{ width: 200 }}
                        placeholder={"Filtra tratta"}
                    />
                    <Input
                        allowClear
                        value={refIdSearchText}
                        onChange={({ target }) => setRefIdSearchText(target.value)}
                        style={{ width: 200 }}
                        placeholder={"Filtra voce"}
                    />
                </div>

                <Row style={{ marginTop: 20 }}>
                    <Col flex={"700px"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <span style={{ width: 70, fontWeight: "bold" }}>Tratta</span>
                            <span style={{ width: 150, fontWeight: "bold" }}>Articolo</span>
                        </div>
                    </Col>
                    <Col flex={"auto"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                            }}
                        >
                            <span style={{ width: 150, fontWeight: "bold", textAlign: "end" }}>
                                Competenza
                            </span>
                            <span style={{ width: 150, fontWeight: "bold", textAlign: "end" }}>
                                Quantità
                            </span>
                            {report.hasPrices && (
                                <>
                                    <span
                                        style={{ width: 150, fontWeight: "bold", textAlign: "end" }}
                                    >
                                        Importo unitario
                                    </span>
                                    <span
                                        style={{ width: 150, fontWeight: "bold", textAlign: "end" }}
                                    >
                                        Importo totale
                                    </span>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
                <Tree showLine expandedKeys={keys()} treeData={tableData} height={700}></Tree>
                <Row style={{ marginTop: 20 }}>
                    <Col flex={"800px"}></Col>
                    <Col flex={"auto"}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                            }}
                        >
                            <span style={{ width: 150, fontWeight: "bold", textAlign: "end" }}>
                                Totale
                            </span>

                            <span style={{ width: 150, textAlign: "end" }}>
                                {filteredLines
                                    .reduce((acc, curr) => acc + curr.quantity, 0)
                                    .toLocaleString("it-IT", {
                                        minimumFractionDigits: 2,
                                    })}
                            </span>
                            <PermissionRequired name="global.viewPrices">
                                <span style={{ width: 150, textAlign: "end" }}></span>
                                <span style={{ width: 150, textAlign: "end" }}>
                                    {report.summary?.totalPrice.toLocaleString("it-IT", {
                                        minimumFractionDigits: 2,
                                    })}
                                </span>
                            </PermissionRequired>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}
